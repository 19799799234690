import React, { Component } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Meta from '../components/Meta/Meta';
import Header from '../components/Header';
import Footer from '../components/Footer';

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100vh;
}
*, *:before, *:after {
 box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 2;
  height: 100vh;
  color: hsl(210, 36%, 96%);
  background: hsl(205, 82%, 33%);
}
a {
  color: hsl(210, 36%, 96%);
  text-decoration: none;
}
`;

const PageStyle = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const InnerStyle = styled.div`
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class HomeLayout extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Meta />
        <PageStyle>
          <Header />
          <InnerStyle>{this.props.children}</InnerStyle>
          <Footer />
        </PageStyle>
      </>
    );
  }
}

export default HomeLayout;
